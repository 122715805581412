import React, {useState} from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser"
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from "react-share";
import {
	EmailIcon,
	FacebookIcon,
	LinkedinIcon,
	TwitterIcon,
	WhatsappIcon
} from "react-share";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { PageBreadcrumbs } from "../components/common/site/utils"
import Breadcrumbs from "../components/BreadCrumbs/DetailsBreadCrumbs"
import JobApplyModal from '../components/EnquireModal/JobApplyModal';
import SEO from '../components/Seo/seo';
const CareerDetailsTemplate = ({data}) => {
    const [modalShow, setModalShow] = useState(false)

    const career = data.glstrapi.career;
    const aboutOmHome = data.glstrapi.globalModule.career_about_omhome

    // Social share
	const shareurl = typeof window !== 'undefined' ? window.location.href : ''

	const trackerShare = (event) => {    
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event': 'Share - social icons',
		'formType': event + ' - ' +shareurl,
		'formId': 'Share',
		'formName': 'Share',
		'formLabel': 'Share'
	});    
	}
	// Social share

    return (
        <div className="career-details-template">
            <SEO
            title={career.seo_meta ? career.seo_meta.title : career.title}
            description={career.seo_meta ? career.seo_meta.description : ""}
            />
            <Header />
            <Breadcrumbs breadcrumbs={PageBreadcrumbs("property-jobs-in-dubai")} title={career.title}/>

            <Container>
                <div className="career-details-wrapper">
                    <Row className="d-flex justify-content-center">
                        <Col lg={8}>
                            <div className="career-details-header">
                                <h1>{career?.title}</h1>
                                <a href="javascript:;" onClick={() => setModalShow(true)} className="btn btn-black d-lg-flex d-none">Apply for this job</a>

                                <div className="d-lg-none d-flex career-details-social-wrapper">
                                    <div className="career-details-social">
                                        <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                            <i className="icon icon-fb-round"></i>
                                        </FacebookShareButton>
                                    </div>
                                    <div className="career-details-social">
                                        <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                            <i className="icon icon-twitter-round"></i>
                                        </TwitterShareButton>
                                    </div>
                                    <div className="career-details-social">
                                        <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                            <i className="icon icon-linkedin-round"></i>
                                        </LinkedinShareButton>
                                    </div>
                                </div>
                            </div>

                            <div className="career-details-salary">
                                <ul className="list-unstyled">
                                    {
                                        career?.salary &&
                                        <li className="d-flex align-items-center">
                                            <div className="salary-heading">Salary Range:</div>
                                            <div className="salary-text">{career?.salary}</div>
                                        </li>
                                    }
                                    {
                                        career?.hours &&
                                        <li className="d-flex align-items-center">
                                            <div className="salary-heading">Hours:</div>
                                            <div className="salary-text">{career?.hours}</div>
                                        </li>
                                    }
                                    {
                                        career?.location &&
                                        <li className="d-flex align-items-center">
                                            <div className="salary-heading">Location:</div>
                                            <div className="salary-text">{career?.location}</div>
                                        </li>
                                    }
                                    {
                                        career?.career_department?.department &&
                                        <li className="d-flex align-items-center">
                                            <div className="salary-heading">Department:</div>
                                            <div className="salary-text">{career?.career_department?.department}</div>
                                        </li>
                                    }
                                    {
                                        career?.date_posted &&
                                        <li className="d-flex align-items-center">
                                            <div className="salary-heading">Date Posted:</div>
                                            <div className="salary-text">{career?.date_posted}</div>
                                        </li>
                                    }
                                </ul>

                                <div className="d-lg-block d-none career-details-social-wrapper">
                                    <div className="career-details-social">
                                        <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                            <i className="icon icon-fb-round"></i>
                                        </FacebookShareButton>
                                    </div>
                                    <div className="career-details-social">
                                        <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                            <i className="icon icon-twitter-round"></i>
                                        </TwitterShareButton>
                                    </div>
                                    <div className="career-details-social">
                                        <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                            <i className="icon icon-linkedin-round"></i>
                                        </LinkedinShareButton>
                                    </div>
                                </div>
                            </div>

                            {
                                career?.job_description &&
                                <div className="career-details-desc">
                                    <p>{parse(career?.job_description)}{parse(aboutOmHome)}</p>
                                </div>
                            }
                            <div className="career-details-footer-btn">
                                <a href="javascript:;" onClick={() => setModalShow(true)} className="btn btn-black d-lg-flex d-none">Apply for this job</a>
                            </div>
                        </Col>
                    </Row>
                    <div className="career-details-fixed-btn d-lg-none d-flex">
                        <a href="javascript:;" onClick={() => setModalShow(true)} className="btn btn-black">Apply for this job</a>
                    </div>
                </div>
            </Container>
            <JobApplyModal show={modalShow} onHide={() => setModalShow(false)} jobname={career?.title}/>
            <Footer />
        </div>
    )
}

export default CareerDetailsTemplate

export const pageQuery = graphql`
  query GetCareersPage($articleId: ID!) {
    glstrapi {
        career(id: $articleId, publicationState: LIVE) {
            id
            url
            title
            salary
            hours
            location
            date_posted
            job_description
            seo_meta {
                description
                id
                title
                keywords
                image {
                  url
                }
              }
            career_department {
                department
            }
        }
        globalModule {
            career_about_omhome
        }
    }
  }
`